import axios from 'axios'
import getConfig from 'next/config'
import qs from 'qs'
import { serialize } from 'object-to-formdata'
import { toast } from 'react-toastify'
import Router from 'next/router'
import auth from '../features/Auth/authSlice'
import { deleteCookies } from './helpers/cookies'

axios.defaults.paramsSerializer = (params) => qs.stringify(params)

const { publicRuntimeConfig } = getConfig()
const isClient = () => typeof window !== 'undefined'
const API_URL = isClient()
  ? publicRuntimeConfig.browserApiUrl
  : publicRuntimeConfig.apiUrl

const fullUrl = (url, slug) => {
  return slug ? `${API_URL}/${url}/${slug}` : `${API_URL}/${url}`
}

export default function createApi(store) {
  const getHeaders = () => {
    const { token } = store.getState().auth

    return token ? { Authorization: `Bearer ${token}` } : undefined
  }

  // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvcG9seW1lci1tYXN0ZXIuc3RhZ2UuZGV2LmZhbWlseVwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYwNzYzMDE0MywiZXhwIjoxNjEyODE0MTQzLCJuYmYiOjE2MDc2MzAxNDMsImp0aSI6IkZWVjdLalczelNyU25xYWciLCJzdWIiOjM5LCJwcnYiOiI0ZjllNTAzNDQzNzIwNzVkOWRiYTc1MDAxZmJhZWMwMjZhYTZhMTc4In0.px2B_3Xaba8syBlB3AfQMmbNf7CHick3wdIN235bb_8

  async function logout() {
    function clearAndRedirect() {
      deleteCookies('polymer_session_token')
      Router.replace('/auth/login')
      // toast.error('Время сессии истекло')
    }

    await store
      .dispatch(auth.logout(true))
      .then(() => {
        clearAndRedirect()
      })
      .catch(() => {
        clearAndRedirect()
      })
  }

  const get = (url) => (query = {}) => {
    const { cancelToken = axios.CancelToken.source().token, ...params } = query

    return axios
      .get(fullUrl(url), { cancelToken, params, headers: getHeaders() })
      .then((response) => response.data)
      .catch(async (error) => {
        console.log(error)
        // todo 2

        if (error.response.status === 401) {
          await logout()
        }

        const { config } = error.response
        // eslint-disable-next-line no-shadow
        const { method, url, params } = config
        return {
          result: 'error',
          statusCode: error.response.status,
          config: { method, url, params },
          data: error.response.data,
        }
      })
  }

  const post = (url) => async (query = {}, withFile = false) => {
    const {
      slug = '',
      cancelToken = axios.CancelToken.source().token,
      ...data
    } = query

    try {
      const headers = () => {
        const formData = { 'Content-Type': 'multipart/form-data' }
        if (withFile) {
          return getHeaders()
            ? { ...getHeaders(), ...formData }
            : { ...formData }
        }

        return getHeaders()
      }

      const response = await axios.post(
        fullUrl(url, slug),
        withFile ? serialize(data) : data,
        {
          cancelToken,
          headers: headers(),
        },
      )

      return response.data
    } catch (e) {
      console.log(e)
      if (e.response.status === 401) {
        logout()
      }

      throw e.response ? e.response.data : e
    }
  }

  return {
    home: {
      index: get('auth/index'),
      hideNotification: post('auth/index/hide_notification'),
    },

    auth: {
      login: post('auth/login'),
      logout: post('auth/logout'),
      register: post('partner_request/register_request'),
      recovery: post('partner_request/password_reset'),
      user: post('auth/me'),
    },

    catalog: {
      categories: get('auth/categories'),
      products: get('auth/goods'),
      catalogExtensionRequest: post('auth/partner_request/catalog_expand'),
      how_to: get('auth/howto'),
    },

    cart: {
      show: get('auth/cart'),
      change: post('auth/cart/change'),
      remove: post('auth/cart/remove'),
    },

    profile: {
      changeSettings: post('auth/change_select'),
      changeProfileRequest: post('auth/partner_request/profile_change'),
    },

    order: {
      history: get('auth/orders_history'),
      finance: get('auth/finances'),
      send: post('auth/order'),
      orderCancellation: post('auth/partner_request/order_cancel'),
      actReconciliationRequest: post('auth/partner_request/comparison'),
      repeat: post('auth/repeat_order'),
    },

    favorites: {
      change: post('auth/toggle_favorites'),
    },

    tickets: {
      tasks: get('auth/get_tasks'),
      messages: get('auth/get_messages'),
      sendMessage: post('auth/qa_message'),
      sendTask: post('auth/partner_request/general'),
    },

    articles: {
      list: get('auth/news'),
      show: get('auth/new'),
    },
  }
}
