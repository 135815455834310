import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'

import Logo from '../components/Logo'
import NextLink from '../components/Next/NextLink'

export default function Auth({ children, hiddenNav }) {
  const navigation = (
    <StyledFormNav>
      <NextLink href="/auth/login">
        <StyledFormLink>Вход</StyledFormLink>
      </NextLink>
      <NextLink href="/auth/register">
        <StyledFormLink>Регистрация</StyledFormLink>
      </NextLink>
    </StyledFormNav>
  )
  return (
    <>
      <StyledGrid>
        <StyledCell withForm>
          <StyledTop>
            <StyledLogo>
              <Logo />
            </StyledLogo>
          </StyledTop>

          <StyledMiddle>
            <StyledForm>
              {!hiddenNav && navigation}
              {children}
            </StyledForm>
          </StyledMiddle>

          <StyledBottom>
            <StyledContactPhone href="tel:+375296850936">
              +375 29 685-09-36
            </StyledContactPhone>
          </StyledBottom>
        </StyledCell>
        <StyledCell withBg />
      </StyledGrid>
    </>
  )
}

Auth.defaultProps = {
  hiddenNav: false,
}

Auth.propTypes = {
  children: PropTypes.node.isRequired,
  hiddenNav: PropTypes.bool,
}

const StyledGrid = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
`

const StyledCellWithBg = css`
  background-image: url(${require('../assets/images/crystals.jpg')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: ${(props) => props.theme.placeholderColor};

  ${down('xl')} {
    display: none;
  }
`

const StyledCellWithForm = css`
  padding: 70px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${down('sm')} {
    padding: 35px 20px;
  }
`

const StyledCell = styled.div`
  width: 50%;

  ${down('xl')} {
    width: 100%;
  }

  ${(props) => props.withBg && StyledCellWithBg}
  ${(props) => props.withForm && StyledCellWithForm}
`

const StyledTop = styled.div`
  text-align: center;
`

const StyledLogo = styled.div``

const StyledMiddle = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
`

const StyledForm = styled.div`
  display: inline-block;
  min-width: 280px;
  min-height: 280px;
  padding: 35px;
  background-color: ${(props) => props.theme.white};
  box-shadow: ${(props) => props.theme.boxShadow};
  border-radius: ${(props) => props.theme.borderRadius};
  text-align: left;
  max-width: 100%;

  ${down('sm')} {
    padding: 20px;
    padding-top: 25px;
  }
`

const StyledFormNav = styled.div`
  margin: -5px -10px;
`
const StyledFormLink = styled.a`
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${(props) => props.theme.grayLight};
  font-size: 26px;
  line-height: 29px;
  transition: color ${(props) => props.theme.transitionTime};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.blueLight};
  }

  ${down('sm')} {
    font-size: 23px;
    line-height: 26px;
  }

  &.next-link-active {
    color: ${(props) => props.theme.colorSecondary};
    font-weight: 500;
  }
`

const StyledBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledContactPhone = styled.a`
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 5px;
  margin: -5px;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.colorSecondary};
  transition: color ${(props) => props.theme.transitionTime};

  &:hover,
  &:focus {
    color: ${(props) => props.theme.blueLight};
  }
`

const StyledAgreementLink = styled.a`
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 5px;
  margin: -5px;
  color: ${(props) => props.theme.grayDark};
  font-size: 14px;
  line-height: 17px;
  transition: color ${(props) => props.theme.transitionTime};
  text-align: center;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.blueLight};
  }
`
