import PropTypes from 'prop-types'
import { down } from 'styled-breakpoints'
import styled from 'styled-components'
import Logo from '../components/Logo'

export default function Error({ children }) {
  return (
    <StyledErrorWrapper>
      <Logo />
      <StyledErrorWrapper.Content>{children}</StyledErrorWrapper.Content>
    </StyledErrorWrapper>
  )
}

const StyledErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 3.7rem 0;

  ${down('md')} {
    padding: 2rem 0;
  }
`

StyledErrorWrapper.Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

Error.propTypes = {
  children: PropTypes.node.isRequired,
}
