import styled from 'styled-components'
import PropTypes from 'prop-types'

function LabelCount({ count }) {
  return (
    <StyledLabelCount>
      <StyledLabelCountText>{count}</StyledLabelCountText>
    </StyledLabelCount>
  )
}

LabelCount.defaultProps = {
  count: 0,
}

LabelCount.propTypes = {
  count: PropTypes.number,
}

const StyledLabelCount = styled.div`
  font-size: 0;
  line-height: 0;
  display: inline-flex;
  height: 19px;
  min-width: 19px;
  border-radius: 16px;
  background-color: ${(props) => props.theme.red};
  padding: 2px 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const StyledLabelCountText = styled.div`
  color: ${(props) => props.theme.white};
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
`

export default LabelCount
