import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { useUserAgent } from 'next-useragent'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { useRouter } from 'next/router'
import { ToastContainer } from 'react-toastify'
import _debounce from 'lodash/debounce'
import ym, { YMInitializer } from 'react-yandex-metrika'
import NextProgress from '../components/Next/NextProgress'
import { breakpoints, colors, theme } from '../assets/styles/config'
import Page from '../layouts/Page'
import createStore from '../store'
import { getCookies, deleteCookies } from '../services/helpers/cookies'
import auth from '../features/Auth/authSlice'
import { ResponsiveProvider } from '../context/ResponsiveContext'
import redirect from '../services/helpers/redirect'
import 'tippy.js/dist/tippy.css'
import 'react-tippy/dist/tippy.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-day-picker/lib/style.css'
import catalog from '../features/Catalog/catalogSlice'

const styleConfig = {
  breakpoints,
  ...colors,
  ...theme,
}

const YM_TRACKING_ID = [86665882]

function initVhFix() {
  function vhFix() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  window.addEventListener('resize', _debounce(vhFix, 150))

  vhFix()

  return () => window.removeEventListener('resize', vhFix)
}

const defaultTitle = 'Главная'
const defaultDescription =
  'Наша компания является официальным поставщиком полимерного сырья (полипропилен, полиэтилен, поликарбонат и многое др.)'

function App({ Component, pageProps, initialReduxState }) {
  const store = createStore(initialReduxState)
  const router = useRouter()

  const layout = pageProps?.layout || 'default'
  const layoutConfig = pageProps?.layoutConfig || {}
  const metaTitle = pageProps?.metaTitle || defaultTitle
  const metaDescription = pageProps?.metaDescription || defaultDescription
  const titlePage = pageProps?.titlePage || null

  useEffect(initVhFix, [])

  useEffect(() => {
    const handleRouteChange = (url) => {
      ym('hit', url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <YMInitializer
        accounts={YM_TRACKING_ID}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
        version="2"
      />
      <Provider store={store}>
        <ThemeProvider theme={styleConfig}>
          <ResponsiveProvider>
            <Page
              metaTitle={metaTitle}
              metaDescription={metaDescription}
              layout={layout}
              layoutConfig={layoutConfig}
              titlePage={titlePage}
            >
              <NextProgress />
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...pageProps} />
            </Page>
            <StyledToastContainer>
              <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar
                pauseOnFocusLoss={false}
                limit={3}
                closeButton={false}
                draggablePercent={20}
              />
            </StyledToastContainer>
          </ResponsiveProvider>
        </ThemeProvider>
      </Provider>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  const isServer = typeof window === 'undefined'

  if (isServer) {
    const { browser } = useUserAgent(ctx.req.headers['user-agent'])

    if (browser === 'Internet Explorer') {
      redirect('https://outdatedbrowser.com/ru', ctx.res)
    }
  }

  let pageProps = {}
  const reduxStore = createStore()
  const sessionToken = getCookies('polymer_session_token', ctx.req)
  const isAuthPage = ctx.pathname.startsWith('/auth')
  const isErrorPage = ctx.pathname.startsWith('/_error')
  const isAgreementPage = ctx.pathname.startsWith('/agreement')

  let isAuth = !!sessionToken

  if (isAuth) {
    try {
      await reduxStore.dispatch(auth.fetchUser(sessionToken))
      await reduxStore.dispatch(catalog.fetchHowTo())

      // setCookies('polymer_session_token', sessionToken, ctx.res)
    } catch (e) {
      isAuth = false
      deleteCookies('polymer_session_token', ctx.res)
    }
  }

  if (!isErrorPage && !isAgreementPage) {
    if (isAuth) {
      if (isAuthPage) {
        redirect('/', ctx.res)
      }
    } else if (!isAuthPage) {
      redirect('/auth/login', ctx.res)
    }
  }

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps({ ...ctx, reduxStore })
  }

  return {
    pageProps,
    initialReduxState: reduxStore.getState(),
  }
}

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.objectOf(PropTypes.any).isRequired,
  initialReduxState: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default App

const StyledToastContainer = styled.div`
  .Toastify__toast-container {
    width: 290px;

    @media (max-width: 480px) {
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 280px;
      max-width: 100%;
    }
  }
  .Toastify__toast {
    min-height: 48px;
    background: #11283e;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 9px 15px 10px;
    margin-bottom: 15px;
    text-align: center;
  }
  .Toastify__toast--error {
    background: #11283e;
  }
  .Toastify__toast--warning {
    background: #11283e;
  }
  .Toastify__toast--success {
    background: #11283e;
  }
  .Toastify__toast-body {
    font-family: ${theme.fontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: ${colors.white};
    margin-top: 0;
    margin-bottom: 0;
  }
  .Toastify__progress-bar {
  }
`
