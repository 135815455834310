import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import auth from '../authSlice'
import { deleteCookies } from '../../../services/helpers/cookies'

function Logout() {
  const dispatch = useDispatch()
  const router = useRouter()

  async function handleClickLogout(e) {
    e.preventDefault()

    await dispatch(auth.logout()).then(() => {
      deleteCookies('polymer_session_token')
      router.replace('/auth/login')
    })
  }

  return (
    <StyledLogout href="#" onClick={(e) => handleClickLogout(e)}>
      Выйти
    </StyledLogout>
  )
}

const StyledLogout = styled.a`
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.colorSecondary};
  transition: color ${(props) => props.theme.transitionTime};
  text-decoration: none;
  display: block;
  padding: 8px;

  &:hover {
    color: ${(props) => props.theme.blueLight};
  }
`

Logout.defaultProps = {}

Logout.propTypes = {}

export default Logout
