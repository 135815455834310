import Router from 'next/router'

export default function redirect(to, res = null) {
  if (res) {
    res.writeHead(302, {
      Location: to,
    })
    res.end()
  } else {
    Router.push(to)
  }
}
