import { createSlice } from '@reduxjs/toolkit'
import qs from 'qs'
import removeEmpty from '../../services/helpers/removeEmpty'
import { loggedOut } from '../Auth/authSlice'
import { favoritesChanged } from '../Favorites/favoritesSlice'

const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    categories: [],
    products: {},
    howTo: null,
  },
  reducers: {
    categoriesAdded(state, { payload: { categories } }) {
      state.categories = categories
    },
    productsAdded(state, { payload: { queryString, products } }) {
      state.products[queryString] = products
    },
    howToAdded(state, { payload: { howTo } }) {
      state.howTo = howTo
    },
  },
  extraReducers: {
    [loggedOut]: (state) => {
      state.categories = []
      state.products = {}
      state.howTo = null
    },
    [favoritesChanged]: (state) => {
      // Чистим кеш избранного при добавлении/удалении избранного

      Object.keys(state.products).forEach((item) => {
        if (item.includes('favorites')) {
          delete state.products[item]
        }
      })
    },
  },
})

const getProducts = (query) => (state) => {
  return state.catalog.products[query]
}

const getCategories = (state) => {
  return state.catalog.categories.length ? state.catalog.categories : null
}

const getHowTo = (state) => {
  return state.catalog.howTo
}

const fetchCategories = () => async (dispatch, getState, { api }) => {
  const categories = getCategories(getState())

  if (categories) {
    return categories
  }

  const response = await api.catalog.categories()

  dispatch(catalogSlice.actions.categoriesAdded({ categories: response.data }))
  return response.data
}

const fetchHowTo = () => async (dispatch, getState, { api }) => {
  const howTo = getHowTo(getState())

  if (howTo) {
    return howTo
  }

  const response = await api.catalog.how_to()

  dispatch(catalogSlice.actions.howToAdded({ howTo: response.data }))

  return response.data
}

const fetchProducts = (query = {}) => async (dispatch, getState, { api }) => {
  const cleanQuery = removeEmpty({
    per_page: 20,
    ...query,
  })
  const queryString = qs.stringify(cleanQuery)

  const cachedProducts = getProducts(queryString)(getState())

  if (cachedProducts) {
    return cachedProducts
  }

  const response = await api.catalog.products(cleanQuery)
  const products = response.data.paginator

  dispatch(catalogSlice.actions.productsAdded({ queryString, products }))

  return products
}

const catalogExtensionRequest = () => async (dispatch, getState, { api }) => {
  const result = await api.catalog.catalogExtensionRequest()

  return result.status === 'success'
}

export const { categoriesAdded, productsAdded } = catalogSlice.actions

export default {
  getHowTo,
  catalogExtensionRequest,
  fetchHowTo,
  fetchCategories,
  fetchProducts,
  reducer: catalogSlice.reducer,
}
