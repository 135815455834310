import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import { useClickAway } from 'ahooks'
import { ellipsis } from 'polished'
import { useRouter } from 'next/router'
import DownIcon from '../assets/icons/down.svg?svg-sprite'

function Dropdown({ title, children, position, fullWidth }) {
  const [isOpen, setDropState] = useState(false)
  const ref = useRef()
  const router = useRouter()

  function handleToggle(e) {
    e.preventDefault()
    setDropState(() => !isOpen)
  }

  useClickAway(() => {
    setDropState(false)
  }, ref)

  useEffect(() => {
    if (isOpen) {
      setDropState(false)
    }
  }, [router.asPath])

  return (
    <StyledDropdown ref={ref} isOpen={isOpen} position={position}>
      <StyledDropdownToggler onClick={(e) => handleToggle(e)} href="#">
        <StyledDropdownTogglerText>{title}</StyledDropdownTogglerText>
        <DownIcon width="10" height="7" />
      </StyledDropdownToggler>
      <StyledDropdownBody fullWidth={fullWidth}>
        <StyledDropdownBodyInner>{children}</StyledDropdownBodyInner>
      </StyledDropdownBody>
    </StyledDropdown>
  )
}

Dropdown.defaultProps = {
  position: 'left',
  fullWidth: false,
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  title: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
}

const StyledDropdownTogglerText = styled.span`
  ${ellipsis('100%')};
  flex: 1 1 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.colorSecondary};
  margin-right: 14px;
`
export const StyledDropdownToggler = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  max-width: 100%;
  max-width: 240px;
  color: ${(props) => props.theme.colorSecondary};
  border: 1px solid ${(props) => props.theme.grayLight};
  border-radius: 48px;
  height: 48px;
  transition: border-color ${(props) => props.theme.transitionTime};
  justify-content: center;
  align-items: center;
  font-size: 0;
  line-height: 0;
  padding-left: 24px;
  padding-right: 24px;

  svg {
    flex: 0 0 auto;
  }

  &:hover {
    border-color: ${(props) => props.theme.blue};
  }
`

const StyledDropdownBody = styled.div`
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
  min-width: 236px;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.15s ease, transform 0.15s ease;
  padding-top: 8px;
  width: ${(fullWidth) => (fullWidth ? 'max-content' : null)};
`
const StyledDropdownBodyInner = styled.div`
  padding: 14px;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 6px 21px rgba(15, 62, 109, 0.09);
  border-radius: ${(props) => props.theme.borderRadius};
`

const StyledDropdownIsOpen = css`
  position: relative;
  display: inline-block;

  ${StyledDropdownBody} {
    height: auto;
    visibility: visible;
    overflow: visible;
    opacity: 1;
    transform: translateY(0);
  }
`

const StyledDropdownRight = css`
  ${StyledDropdownBody} {
    left: auto;
    right: 0;
  }
`

const StyledDropdownLeft = css`
  ${StyledDropdownBody} {
    left: 0;
    right: auto;
  }
`

export const StyledDropdown = styled.div`
  position: relative;
  display: inline-block;

  ${(props) => props.isOpen && StyledDropdownIsOpen}
  ${(props) => props.position === 'right' && StyledDropdownRight}
  ${(props) =>
    props.position === 'left' && StyledDropdownLeft}
`

export default Dropdown
