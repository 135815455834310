import { createSelector, createSlice } from '@reduxjs/toolkit'
import { loggedOut } from '../Auth/authSlice'

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    calendar: {},
    news: [],
    notifications: [],
    orders: [],
    tasks: [],
  },
  reducers: {
    calendarAdded(state, { payload: calendar }) {
      state.calendar = calendar
    },
    newsAdded(state, { payload: news }) {
      state.news = news
    },
    notificationsAdded(state, { payload: notifications }) {
      state.notifications = notifications
    },
    notificationRemoved(state, { payload: id }) {
      state.notifications = state.notifications.filter((item) => item.id !== id)
    },
    ordersAdded(state, { payload: orders }) {
      state.orders = orders
    },
    tasksAdded(state, { payload: tasks }) {
      state.tasks = tasks
    },
  },
  extraReducers: {
    [loggedOut]: (state) => {
      state.calendar = {}
      state.news = []
      state.notifications = []
      state.orders = []
      state.tasks = []
    },
  },
})

const fetchData = () => async (dispatch, getState, { api }) => {
  const response = await api.home.index()

  const calendar = response.data?.calendar || {}
  const news = response.data?.news?.data || []
  const notifications = response.data?.notifications || []
  const orders = response.data?.orders?.data || []
  const tasks = response.data?.tasks || []

  dispatch(homeSlice.actions.calendarAdded(calendar))
  dispatch(homeSlice.actions.newsAdded(news))
  dispatch(homeSlice.actions.notificationsAdded(notifications))
  dispatch(homeSlice.actions.ordersAdded(orders))
  dispatch(homeSlice.actions.tasksAdded(tasks))

  return response?.data || {}
}

const getCalendar = createSelector(
  (state) => state.home,
  (home) => {
    const { calendar } = home
    return {
      calendar,
    }
  },
)

const getNews = createSelector(
  (state) => state.home,
  (home) => {
    const news = home.news.slice(0, 4)
    return {
      news,
    }
  },
)

const getNotifications = createSelector(
  (state) => state.home,
  (home) => {
    const notifications = home.notifications.slice(0, 5)
    return {
      notifications,
    }
  },
)

const getOrders = createSelector(
  (state) => state.home,
  (home) => {
    const orders = home.orders.slice(0, 3)
    return {
      orders,
    }
  },
)

const getTasks = createSelector(
  (state) => state.home,
  (home) => {
    const tasks = home.tasks.slice(0, 3)
    return {
      tasks,
    }
  },
)

const hideNotificationById = (data) => async (dispatch, getState, { api }) => {
  const response = await api.home.hideNotification(data)
  const isSuccess = response?.status === 'success'

  if (isSuccess) {
    dispatch(homeSlice.actions.notificationRemoved(data.notification_id))
  }

  return isSuccess
}

export default {
  getCalendar,
  getNews,
  getNotifications,
  getOrders,
  getTasks,
  fetchData,
  hideNotificationById,
  reducer: homeSlice.reducer,
}
