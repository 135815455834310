import { createSelector, createSlice } from '@reduxjs/toolkit'
import { loggedIn, loggedOut } from '../Auth/authSlice'
import { orderCompleted, ordersHistoryCleared } from '../Orders/OrdersSlice'
import { counterAdded } from '../Tickets/TicketsSlice'

const countersSlice = createSlice({
  name: 'counters',
  initialState: {
    counterQa: 0,
    counterOrders: 0,
  },
  reducers: {},
  extraReducers: {
    [loggedIn]: (state, { payload: { user } }) => {
      state.counterQa = user.counters.new_qa_count
      state.counterOrders = user.counters.new_orders_count
    },
    [loggedOut]: (state) => {
      Object.keys(state).forEach((item) => {
        state[item] = 0
      })
    },
    [orderCompleted]: (state, { payload: { order } }) => {
      state.counterOrders = order.counters.new_orders_count
    },
    [ordersHistoryCleared]: (state) => {
      state.counterOrders = 0
    },
    [counterAdded]: (state, { payload: { counter } }) => {
      state.counterQa = counter
    },
  },
})

const getCounters = createSelector(
  (state) => state.counters,
  (counters) => {
    return {
      counters,
    }
  },
)

export default {
  getCounters,
  reducer: countersSlice.reducer,
}
