import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import { useSelector } from 'react-redux'
import { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { transparentize, ellipsis } from 'polished'
import { useClickAway } from 'ahooks'
import { up, down } from 'styled-breakpoints'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { Spin as Hamburger } from 'hamburger-react'
import userSlice from '../features/User/userSlice'
import NextLink from '../components/Next/NextLink'
import Logo from '../components/Logo'
import IconHome from '../assets/icons/menu_home.svg?sprite'
import IconFaq from '../assets/icons/menu_faq.svg?sprite'
import IconFinance from '../assets/icons/menu_finance.svg?sprite'
import IconCatalog from '../assets/icons/menu_catalog.svg?sprite'
import IconOrders from '../assets/icons/menu_orders.svg?sprite'
import IconProfile from '../assets/icons/menu_profile.svg?sprite'
import IconAttach from '../assets/icons/attachment_msg.svg?sprite'
import { useResponsiveContext } from '../context/ResponsiveContext'
import Title from '../components/Title'
import Dropdown, { StyledDropdown } from '../components/Dropdown'
import Logout from '../features/Auth/components/Logout'
import CartPreviewButton from '../features/Cart/components/CartPreview/CartPreviewButton'
import CustomTooltip from '../components/Tooltip'
import countersSlice from '../features/Counters/countersSlice'
import LabelCount from '../components/LabelCount'
import setCookies, { getCookies } from '../services/helpers/cookies'
import CustomModal from '../components/Modal'
import catalog from '../features/Catalog/catalogSlice'
import WysiwygOutput from '../components/WysiwygOutput'

const menuSidebar = [
  {
    id: 1,
    name: 'Главная страница',
    path: '/',
    icon: <IconHome width="32" height="32" />,
  },
  {
    id: 2,
    name: 'Заказы',
    path: '/orders',
    icon: <IconOrders width="32" height="32" />,
    counterName: 'counterOrders',
  },
  {
    id: 3,
    name: 'Каталог',
    path: '/catalog',
    icon: <IconCatalog width="32" height="32" />,
  },
  {
    id: 4,
    name: 'Финансы',
    path: '/finance',
    icon: <IconFinance width="32" height="32" />,
  },
  {
    id: 5,
    name: 'Вопросы и ответы',
    path: '/questions',
    icon: <IconFaq width="32" height="32" />,
    counterName: 'counterQa',
  },
  {
    id: 6,
    name: 'Новости',
    path: '/articles',
    icon: <IconAttach width="32" height="32" />,
  },
  {
    id: 7,
    name: 'Профиль аккаунта',
    path: '/profile',
    icon: <IconProfile width="32" height="32" />,
  },
]

export default function Default({ children, title, hasHowTo }) {
  const { responsive } = useResponsiveContext()
  const [isOpenedMenu, setStateMenu] = useState(false)
  const asideRef = useRef()
  const asideInnerRef = useRef()
  const user = useSelector(userSlice.getUser)
  const partner = user?.partner
  const managerImages = user?.managerImages
  const partnerName = partner?.name || partner?.full_name
  const router = useRouter()
  const { counters } = useSelector(countersSlice.getCounters)
  const htmlContent = useSelector(catalog.getHowTo)
  const [modalIsOpen, setIsOpen] = useState(false)

  function toggleMenu(state) {
    if (!responsive.isXl) {
      if (state) {
        disableBodyScroll(asideInnerRef.current)
      } else {
        enableBodyScroll(asideInnerRef.current)
      }
    }

    setStateMenu(state)
  }

  useEffect(() => {
    if (responsive.isXxxl) {
      toggleMenu(true)
    }

    if (responsive.isXl) {
      enableBodyScroll(asideInnerRef.current)
    } else if (isOpenedMenu) {
      disableBodyScroll(asideInnerRef.current)
    }
  }, [responsive])

  useEffect(() => {
    return () => clearAllBodyScrollLocks()
  }, [])

  useEffect(() => {
    if (isOpenedMenu && !responsive.isXl) {
      toggleMenu(false)
    }
  }, [router.asPath])

  useClickAway(() => {
    if (isOpenedMenu && !responsive.isXl) {
      toggleMenu(false)
    }
  }, asideRef)

  const managerPhones = partner?.manager_phone
  const MenuItemTooltip = (item) => (
    <StyledMenuItemTooltip>{item}</StyledMenuItemTooltip>
  )

  const [helpTooltipOpen, setHelpTooltipState] = useState(false)

  function handleHelpTipClick(e) {
    e.preventDefault()
    setCookies('tooltip_burger', true)
    setHelpTooltipState(false)
  }

  function handleHowToClick(e) {
    e.preventDefault()

    setIsOpen(true)
  }

  function handleModalChange(val) {
    setIsOpen(val)
  }

  useEffect(() => {
    const isClosed = !!getCookies('tooltip_burger')

    if (!isOpenedMenu && responsive.isXl && !isClosed) {
      setHelpTooltipState(true)
    } else if (responsive.isXl) {
      setCookies('tooltip_burger', true)
      setHelpTooltipState(false)
    }
  }, [isOpenedMenu])

  const helpTip = (
    <StyledHelpTip>
      <StyledHelpTipText>
        Нажмите на эту иконку чтобы развернуть или свернуть меню.
      </StyledHelpTipText>
      <StyledHelpTipButton href="#" onClick={handleHelpTipClick}>
        <span>Понятно, закрыть</span>
      </StyledHelpTipButton>
    </StyledHelpTip>
  )

  return (
    <>
      <StyledLayout isOpened={isOpenedMenu}>
        <StyledAside ref={asideRef}>
          <StyledAsideInner ref={asideInnerRef}>
            <StyledAsideTop>
              <CustomTooltip
                position="right"
                popperOptions={{
                  modifiers: {
                    preventOverflow: {
                      boundariesElement: 'window',
                    },
                  },
                }}
                html={helpTip}
                trigger="manual"
                arrowSize="regular"
                open={helpTooltipOpen}
                offset={40}
                disableTouch={false}
                interactive
              >
                <StyledAsideTopButton>
                  <Hamburger
                    direction="right"
                    toggled={isOpenedMenu}
                    toggle={toggleMenu}
                    size={20}
                    duration={0.3}
                  />
                </StyledAsideTopButton>
              </CustomTooltip>

              <StyledAsideTopLogo>
                <Logo />
              </StyledAsideTopLogo>
            </StyledAsideTop>

            <StyledAsideMiddle>
              <StyledAsideMiddleDrop>
                <Dropdown title={partnerName || ''} position="left">
                  <NextLink href="/profile">
                    <StyledDropLink>Профиль аккаунта</StyledDropLink>
                  </NextLink>
                  <Logout />
                </Dropdown>
              </StyledAsideMiddleDrop>
              <StyledAsideNav>
                {menuSidebar?.length > 1 && (
                  <StyledList>
                    {menuSidebar.map((menuItem) => (
                      <StyledListItem key={menuItem.id}>
                        <CustomTooltip
                          position="right"
                          disabled={isOpenedMenu}
                          arrow={false}
                          html={MenuItemTooltip(menuItem.name)}
                          popperOptions={{
                            modifiers: {
                              preventOverflow: {
                                boundariesElement: 'window',
                              },
                            },
                          }}
                        >
                          <NextLink href={menuItem.path}>
                            <StyledNavLink>
                              <StyledNavLinkIcon>
                                {menuItem.icon}
                              </StyledNavLinkIcon>
                              <StyledNavLinkText>
                                <StyledNavLinkTextSelf>
                                  {menuItem.name}
                                </StyledNavLinkTextSelf>
                                {menuItem.counterName &&
                                  counters[menuItem.counterName] && (
                                    <StyledNavLinkCount>
                                      <LabelCount
                                        count={counters[menuItem.counterName]}
                                      />
                                    </StyledNavLinkCount>
                                  )}
                              </StyledNavLinkText>
                            </StyledNavLink>
                          </NextLink>
                        </CustomTooltip>
                      </StyledListItem>
                    ))}
                  </StyledList>
                )}
              </StyledAsideNav>

              <StyledAsideBottom>
                {partner?.manager_fio && (
                  <>
                    <NextLink href="/questions/new-question">
                      <StyledManager>
                        <StyledManagerLeft>
                          <StyledManagerFigure>
                            {managerImages?.original && (
                              <img
                                width={40}
                                height={40}
                                src={managerImages.original}
                                alt={partner.manager_fio}
                              />
                            )}
                          </StyledManagerFigure>
                        </StyledManagerLeft>
                        <StyledManagerRight>
                          <StyledManagerName>
                            {partner.manager_fio}
                          </StyledManagerName>
                          <StyledManagerStatus>
                            Ваш личный менеджер
                          </StyledManagerStatus>
                        </StyledManagerRight>
                      </StyledManager>
                    </NextLink>

                    {(managerPhones.length > 0 || partner?.manager_email) && (
                      <StyledContacts>
                        {managerPhones.length > 0 &&
                          managerPhones.map((item) => (
                            <StyledContact key={item.phone}>
                              <StyledContactSubscribe>
                                {item.type}
                              </StyledContactSubscribe>
                              <StyledContactLink href={`tel:${item.phone}`}>
                                {item.phone}
                              </StyledContactLink>
                            </StyledContact>
                          ))}

                        {partner?.manager_email && (
                          <StyledContact>
                            <StyledContactLink
                              href={`mailto:${partner.manager_email}`}
                            >
                              {partner.manager_email}
                            </StyledContactLink>
                          </StyledContact>
                        )}
                      </StyledContacts>
                    )}
                  </>
                )}

                <NextLink href="/questions/new-question">
                  <StyledFaqLink>
                    <StyledFaqLinkText>Задать вопрос</StyledFaqLinkText>
                    <StyledFaqLinkIcon>
                      <IconFaq width="32" height="32" />
                    </StyledFaqLinkIcon>
                  </StyledFaqLink>
                </NextLink>
              </StyledAsideBottom>
            </StyledAsideMiddle>
          </StyledAsideInner>
        </StyledAside>
        <StyledMain>
          <StyledMobileHeader>
            <StyledMobileHeaderInner>
              <StyledMobileHeaderLeft>
                <StyledMobileToggler>
                  <Hamburger
                    direction="right"
                    toggled={isOpenedMenu}
                    toggle={toggleMenu}
                    size={20}
                    duration={0.3}
                  />
                </StyledMobileToggler>
              </StyledMobileHeaderLeft>
              <StyledMobileHeaderCenter>
                <Logo />
              </StyledMobileHeaderCenter>
              <StyledMobileHeaderRight>
                <CartPreviewButton variant="header" />
              </StyledMobileHeaderRight>
            </StyledMobileHeaderInner>
          </StyledMobileHeader>

          <StyledMainInner>
            <StyledMainHeader>
              <StyledMainHeaderLeft>
                <>
                  {title && <Title>{title}</Title>}
                  {hasHowTo && (
                    <StyledHowTo onClick={handleHowToClick} href="#">
                      <StyledHowToIcon>
                        <IconFaq width={24} height={24} />
                      </StyledHowToIcon>
                      <StyledHowToText>Как сделать заказ?</StyledHowToText>
                    </StyledHowTo>
                  )}
                </>
              </StyledMainHeaderLeft>
              <StyledMainHeaderRight>
                <StyledMainHeaderRightItem>
                  <Dropdown title={partnerName || ''} position="right">
                    <NextLink href="/profile">
                      <StyledDropLink>Профиль аккаунта</StyledDropLink>
                    </NextLink>
                    <Logout />
                  </Dropdown>
                </StyledMainHeaderRightItem>
                <StyledMainHeaderRightItem>
                  <CartPreviewButton variant="full" />
                </StyledMainHeaderRightItem>
              </StyledMainHeaderRight>
            </StyledMainHeader>

            <StyledMainBody>{children}</StyledMainBody>
          </StyledMainInner>
        </StyledMain>
      </StyledLayout>
      <CustomModal
        title="Как сделать заказ?"
        isOpen={modalIsOpen}
        onChange={handleModalChange}
      >
        <WysiwygOutput content={htmlContent} />
      </CustomModal>
    </>
  )
}

Default.defaultProps = {
  hasHowTo: false,
  title: null,
}

Default.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  hasHowTo: PropTypes.bool,
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const StyledHelpTipButton = styled.a`
  display: inline-flex;
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: white;
  background: #1dc3bf;
  border-radius: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 3px 14px;
  transition: background 0.15s ease;

  &:hover {
    background: #1bd6d1;
  }
`

const StyledHelpTip = styled.div`
  padding: 4px;
`
const StyledHelpTipText = styled.div`
  font-size: 15px;
  line-height: 18px;
`

const StyledMenuItemTooltip = styled.div`
  font-size: 16px;
  line-height: 19px;
`

const StyledMainHeaderRightItem = styled.div`
  padding-left: 9px;
  padding-right: 9px;
`

const StyledDropLink = styled.a`
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.colorSecondary};
  transition: color ${(props) => props.theme.transitionTime};
  text-decoration: none;
  display: block;
  padding: 8px;

  &:hover {
    color: ${(props) => props.theme.blueLight};
  }

  &.next-link-active {
    pointer-events: none;
    font-weight: 500;
  }
`

const StyledMainHeaderRight = styled.div`
  display: none;
  flex: 0 0 auto;
  margin-left: -9px;
  margin-right: -9px;

  ${up('xl')} {
    display: flex;
  }
`
const StyledMainHeaderLeft = styled.div`
  margin-right: 30px;
  display: flex;
  align-items: center;

  h2 {
    margin-right: 25px;
  }

  ${down('md')} {
    display: block;
  }
`
const StyledMainHeader = styled.header`
  position: relative;
  z-index: 4;
  padding: 30px ${(props) => props.theme.containerGutters} 27px;

  ${up('md')} {
    padding: 30px ${(props) => props.theme.containerGuttersMd};
  }

  ${up('xl')} {
    padding-top: 10px;
    padding-bottom: 10px;
    height: ${(props) => props.theme.desktopHeaderHeight};
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  ${up('xxl')} {
    padding: 10px ${(props) => props.theme.containerGuttersXxl};
  }
`

const StyledManagerName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  transition: color ${(props) => props.theme.transitionTime};
  color: ${(props) => props.theme.colorSecondary};
`

const StyledManager = styled.a`
  display: inline-flex;
  text-decoration: none;
  flex-wrap: nowrap;
  margin-bottom: 24px;
  width: 236px;

  ${up('xl')} {
    margin-left: 5px;
  }

  &:hover {
    ${StyledManagerName} {
      color: ${(props) => props.theme.blueLight};
    }
  }

  &.next-link-active {
    pointer-events: none;
  }
`

const StyledManagerLeft = styled.div``

const StyledManagerRight = styled.div`
  margin-left: 12px;
  ${up('xl')} {
    display: none;
  }
`

const StyledManagerFigure = styled.figure`
  position: relative;
  margin: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${(props) => props.theme.placeholderColor};
  flex: 0 0 auto;

  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const StyledManagerStatus = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.gray};
  margin-top: 3px;
`

const StyledContacts = styled.div`
  margin-bottom: 24px;

  ${up('xl')} {
    display: none;
  }
`

const StyledContact = styled.div`
  font-size: 0;
  line-height: 0;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const StyledContactSubscribe = styled.div`
  ${ellipsis('100%')};
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => props.theme.gray};
  margin-bottom: 3px;
`

const StyledContactLink = styled.a`
  ${ellipsis('100%')};
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blueLight};
  text-decoration: none;
  padding: 5px;
  margin: -5px;
  transition: color ${(props) => props.theme.transitionTime};

  &:hover {
    color: ${(props) => props.theme.colorSecondary};
  }
`

const StyledFaqLink = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  max-width: 100%;
  color: ${(props) => props.theme.colorSecondary};
  border: 1px solid ${(props) => props.theme.grayLight};
  border-radius: 48px;
  height: 48px;
  transition: border-color ${(props) => props.theme.transitionTime};
  justify-content: center;
  align-items: center;
  font-size: 0;
  line-height: 0;
  padding-left: 24px;
  padding-right: 8px;

  &:hover {
    border-color: ${(props) => props.theme.blue};
  }

  ${up('xl')} {
    padding-left: 0;
    padding-right: 0;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  &.next-link-active {
    pointer-events: none;
  }
`

const StyledFaqLinkText = styled.span`
  ${ellipsis('100%')};
  flex: 1 1 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.colorSecondary};

  ${up('xl')} {
    display: none;
  }
`

const StyledFaqLinkIcon = styled.div`
  flex: 0 0 auto;
`

const StyledAside = styled.aside`
  flex: 0 0 auto;
  background-color: ${(props) => props.theme.white};
  width: ${(props) => props.theme.sidebarWidthOpened};
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: ${(props) => props.theme.zIndexSidebar};
  transition: transform ${(props) => props.theme.transitionTime},
    width ${(props) => props.theme.transitionTime};
  overscroll-behavior: contain;
  will-change: transform, width;
  transform: translate3d(-${(props) => props.theme.sidebarWidthOpened}, 0, 0);
  box-shadow: 0 6px 10px rgba(15, 62, 109, 0.02);

  ${up('xl')} {
    position: sticky;
    top: 0;
    left: auto;
    bottom: auto;
    transform: translate3d(0, 0, 0);
    width: ${(props) => props.theme.sidebarWidthClosed};
  }
`

const StyledAsideTop = styled.div`
  display: none;
  padding-top: 15px;
  padding-left: 21px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  height: ${(props) => props.theme.desktopHeaderHeight};
  background-color: ${(props) => props.theme.white};
  z-index: 1;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;

  ${up('xl')} {
    display: flex;
  }
`
const StyledAsideTopButton = styled.div`
  color: ${(props) => props.theme.colorSecondary};
  opacity: 0.5;
  transition: opacity ${(props) => props.theme.transitionTime};
  margin-right: 7px;

  &:hover {
    opacity: 1;
  }
`
const StyledAsideTopLogo = styled.div`
  display: none;
  max-width: 190px;
  flex: 0 0 auto;
`
const StyledAsideMiddle = styled.div`
  padding: 24px 8px;
  flex-grow: 1;
  flex-direction: column;
  display: flex;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    transition: background 0.3s;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.grayLighter};
  }
  &::-webkit-scrollbar-thumb {
    background: ${(props) => transparentize(0.5, props.theme.grayLight)};
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => transparentize(0.4, props.theme.gray)};
  }
  &::-webkit-scrollbar-thumb:active {
  }

  ${up('xl')} {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.white};
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 100px;
  }
`
const StyledAsideNav = styled.nav`
  margin-bottom: auto;
`
const StyledAsideBottom = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${(props) => props.theme.borderColor};
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 32px;
  overflow: hidden;
  flex: 0 0 auto;

  ${up('xl')} {
    border-top: none;
    padding-top: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
`

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
`

const StyledNavLinkIcon = styled.div`
  font-size: 0;
  line-height: 0;
  color: ${(props) => props.theme.grayDark};
  margin-right: 23px;
  flex: 0 0 auto;
  transition: color ${(props) => props.theme.transitionTime};
`

const StyledListItem = styled.li`
  position: relative;

  > div {
    display: block !important;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const StyledHowTo = styled.a`
  display: none;
  padding: 5px;
  margin: -5px;
  color: ${(props) => props.theme.grayDarken};
  text-decoration: none;
  margin-top: -2px;
  transition: color ${(props) => props.theme.transitionTime};

  ${up('xl')} {
    display: inline-flex;
  }

  &:hover {
    color: ${(props) => props.theme.colorSecondary};
  }
`

const StyledHowToIcon = styled.div`
  font-size: 0;
  line-height: 0;
  margin-right: 3px;
`

const StyledHowToText = styled.span`
  font-size: 16px;
  line-height: 19px;
  margin-top: 3px;
`

const StyledNavLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-decoration: none;
  color: ${(props) => props.theme.blueLight};
  border-radius: 8px;
  height: 48px;
  padding: 5px 15px 5px 21px;
  transition: color ${(props) => props.theme.transitionTime};

  &:hover {
    color: ${(props) => props.theme.colorSecondary};

    ${StyledNavLinkIcon} {
      color: ${(props) => props.theme.colorSecondary};
    }
  }

  &.next-link-active {
    pointer-events: none;
    color: ${(props) => props.theme.colorSecondary};
    background-color: ${(props) => props.theme.grayLightest};
    font-weight: 500;

    ${StyledNavLinkIcon} {
      color: ${(props) => props.theme.colorSecondary};
    }
  }
`
const StyledNavLinkText = styled.span`
  font-size: 0;
  line-height: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`

const StyledNavLinkTextSelf = styled.div`
  ${ellipsis('100%')};

  font-size: 18px;
  line-height: 21px;

  ${up('xl')} {
    display: none;
  }
`

const StyledNavLinkCount = styled.div`
  position: absolute;
  left: 38px;
  top: 8px;

  flex: 0 0 auto;
`

const StyledMain = styled.div`
  position: relative;
  flex: 1 1 auto;
  // will-change: transform, max-width;
  max-width: 100%;
  transition: transform ${(props) => props.theme.transitionTime},
    max-width ${(props) => props.theme.transitionTime};
  min-width: 0;

  ${up('xl')} {
    transform: translate3d(0, 0, 0);
  }
`

const StyledLayoutMenuOpened = css`
  &::before {
    display: block;
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${(props) => transparentize(0.5, props.theme.black)};
    z-index: ${(props) => props.theme.zIndexSidebar - 1};
    animation: ${fadeIn} ${(props) => props.theme.transitionTime};

    ${up('xl')} {
      display: none;
    }
  }

  ${StyledAside} {
    transform: translate3d(0, 0, 0);

    ${up('xl')} {
      width: ${(props) => props.theme.sidebarWidthOpened};
    }
  }

  ${StyledManager} {
    ${up('xl')} {
      margin-left: 0;
    }
  }

  ${StyledFaqLink} {
    ${up('xl')} {
      border-radius: 48px;
      padding-left: 24px;
      padding-right: 8px;
      width: auto;
      height: 48px;
    }
  }

  ${StyledAsideBottom} {
    ${up('xl')} {
      border-top: 1px solid ${(props) => props.theme.borderColor};
      margin-left: 24px;
      margin-right: 24px;
      padding-top: 32px;
    }
  }

  ${StyledNavLinkTextSelf} {
    ${up('xl')} {
      display: flex;
    }
  }

  ${StyledAsideTopLogo} {
    ${up('xl')} {
      display: block;
    }
  }

  ${StyledManagerRight} {
    ${up('xl')} {
      display: block;
    }
  }

  ${StyledContacts} {
    ${up('xl')} {
      display: block;
    }
  }

  ${StyledFaqLinkText} {
    display: inline-block;
  }

  ${StyledNavLinkCount} {
    position: relative;
    top: 1px;
    left: 0;
    margin-left: 8px;
  }

  ${StyledMain} {
    transform: translate3d(${(props) => props.theme.sidebarWidthOpened}, 0, 0);

    ${up('xl')} {
      transform: translate3d(0, 0, 0);
    }
  }
`

const StyledLayout = styled.div`
  flex: 1 1 auto;

  ${up('xl')} {
    display: flex;
    align-items: flex-start;
  }

  ${(props) => props.isOpened && StyledLayoutMenuOpened}
`

const StyledAsideInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  max-height: 100%;

  ${up('xl')} {
    position: relative;
    display: block;
    -webkit-overflow-scrolling: auto;
    overflow-y: visible;
    max-height: none;
    height: 100vh;
  }
`

const StyledMainInner = styled.div``

const StyledMainBody = styled.main`
  padding: 0 ${(props) => props.theme.containerGutters} 30px;

  ${up('md')} {
    padding: 0 ${(props) => props.theme.containerGuttersMd} 30px;
  }

  ${up('xxl')} {
    padding: 0 ${(props) => props.theme.containerGuttersXxl} 30px;
  }
`

const StyledMobileHeader = styled.header`
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.white};
  box-shadow: 0 6px 10px rgba(15, 62, 109, 0.02);
  z-index: ${(props) => props.theme.zIndexMobHeader};

  ${up('xl')} {
    display: none;
  }
`

const StyledMobileHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background-color: ${(props) => props.theme.white};
  height: ${(props) => props.theme.mobileHeaderHeight};
  font-size: 0;
  line-height: 0;
  padding-left: 8px;
  padding-right: 8px;
`

const StyledMobileHeaderLeft = styled.div`
  flex: 0 0 auto;
  min-width: 59px;
`

const StyledMobileHeaderCenter = styled.div`
  max-width: 150px;
  margin-left: 5px;
  margin-right: 5px;
  flex: 0 0 auto;
`

const StyledMobileHeaderRight = styled.div`
  flex: 0 0 auto;
  margin-right: 3px;
  min-width: 59px;
  display: flex;
  justify-content: flex-end;
`

const StyledMobileToggler = styled.div``
const StyledAsideMiddleDrop = styled.div`
  margin-bottom: 24px;
  margin-left: 25px;

  ${StyledDropdown} {
    width: 100%;
    max-width: 100%;
  }

  ${up('xl')} {
    display: none;
  }
`
