import styled from 'styled-components'
import NextLink from './Next/NextLink'

function Logo() {
  return (
    <StyledContainer>
      <NextLink href="/">
        <StyledLinkLogo>
          <img
            src={require('../assets/images/logo-full.svg')}
            alt=""
            width="211"
            height="22"
          />
        </StyledLinkLogo>
      </NextLink>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: inline-block;
  font-size: 0;
  line-height: 0;
`

const StyledLinkLogo = styled.a`
  display: inline-block;
  padding: 5px;

  &.next-link-active {
    pointer-events: none;
  }
`

export default Logo
