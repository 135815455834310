import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import auth from '../features/Auth/authSlice'
import catalog from '../features/Catalog/catalogSlice'
import cart from '../features/Cart/cartSlice'
import user from '../features/User/userSlice'
import favorites from '../features/Favorites/favoritesSlice'
import createApi from '../services/api'
import orders from '../features/Orders/OrdersSlice'
import tickets from '../features/Tickets/TicketsSlice'
import articles from '../features/Articles/ArticlesSlice'
import counters from '../features/Counters/countersSlice'
import home from '../features/Home/HomeSlice'

let globalStore

function initializeStore(preloadedState) {
  const extraArgument = {}

  const store = configureStore({
    reducer: {
      auth: auth.reducer,
      catalog: catalog.reducer,
      cart: cart.reducer,
      user: user.reducer,
      orders: orders.reducer,
      favorites: favorites.reducer,
      tickets: tickets.reducer,
      articles: articles.reducer,
      counters: counters.reducer,
      home: home.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware({
      thunk: {
        extraArgument,
      },
    }),

    devTools: process.env.NODE_ENV !== 'production',
  })

  extraArgument.api = createApi(store)

  return store
}

export default function createStore(initialState) {
  if (typeof window === 'undefined') {
    return initializeStore(initialState)
  }

  if (!globalStore) {
    globalStore = initializeStore(initialState)
  }
  return globalStore
}
